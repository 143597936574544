var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 14, lg: 16 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 8, lg: 6 } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "100%", "margin-bottom": "10px" },
                        attrs: { placeholder: "Keyword Search" },
                        model: {
                          value: _vm.filters.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "keyword", $$v)
                          },
                          expression: "filters.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 24, md: 8, lg: 6 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            "min-width": "100%",
                            "margin-bottom": "10px",
                          },
                          model: {
                            value: _vm.filters.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "type", $$v)
                            },
                            expression: "filters.type",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "all" } }, [
                            _vm._v("Any"),
                          ]),
                          _c("a-select-option", { attrs: { value: "paid" } }, [
                            _vm._v("Paid"),
                          ]),
                          _c(
                            "a-select-option",
                            { attrs: { value: "unpaid" } },
                            [_vm._v("Unpaid")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-table-full",
                  attrs: { title: "Club Billing" },
                },
                [
                  _c("club-billing-invoice-table", {
                    attrs: { filters: _vm.filters },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }